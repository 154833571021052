<template>
	<transition name="errors">
		<Error class="error__page" :number="number" :message="message" :back="true">
			<h1 class="error__name" v-html="splitNumbers(this.number)">
			</h1>
		</Error>
	</transition>
</template>

<script>
import Error from '@/components/Error'

export default {
	name: 'NotFound',
	components: {
		Error
	},
	data() {
		return {
			number: 404,
			message: 'error.not_found'
		}
	},
	methods: {
		splitNumbers (error) {
			let numbers = Array.from(String(error))
			let html = ''

			for (let i = 0; i < numbers.length; i++) {
				html += `<span>${numbers[i]}</span>`
			}
			
			return html
		}
	}
}
</script>